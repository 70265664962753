<template>
  <div>
  <form-wizard
      color="#f88379"
      :title="null"
      :subtitle="null"
      shape="square"
      :finish-button-text="FinishButton"
      back-button-text="Previous"
      class="mb-3"
      ref="wizard"
      @on-complete="formSubmitted"
    >
  

      <tab-content title="Choose Package" :before-change="validationPackage" >
          <b-row class="text-center">
            <b-col>
            <h1 class="pb-3"> <span class=""></span>  <span class="text-danger">Expired</span></h1>
            </b-col>
          </b-row>
          <b-row  class="text-center">
            <b-col>
          <h3 class="pb-3"><span class="">Your {{this.$store.state.userData.service }} package is been expired please renew or upgrade your package to proceed with your account.</span>    </h3>
            </b-col>
          </b-row>

          <b-row class="justify-content-md-center">
            <b-col v-if="this.$store.state.userData.service != 'Bronze'"  lg="3" md="4">
              <a>
                <b-card
                  @click="ClickAble1()"
                  tag="article"
                  style="max-width: 20rem;border-radius: 25px;"
                  class="text-center initial"
                  v-bind:class="{ clickcard: active1 }"
                >
                  <b-avatar
                    size="70px"
                    :variant="activeAvatar1"
                    text="B"
                    v-bind:class="{ clickcard: active1 }"
                  >
                  </b-avatar>
                  <b-card-title
                    class="mt-2 initial"
                    v-bind:class="{ clickcard: active1 }"
                    >Bronze Package</b-card-title
                  >
                  <b-card-text>
                    The advertisement period is 1 month ( 5 products ) on Brand
                    away application, Ad format (app main page) For free.
                  </b-card-text>
                  <hr />
                  <b-card-text>
                    <strong> Free </strong> 5 products
                  </b-card-text>
                </b-card>
              </a>
            </b-col>

            <b-col lg="3" md="4">
              <a>
                <b-card
                  @click="ClickAble2()"
                  tag="article"
                  style="max-width: 20rem;border-radius: 25px;"
                  class="text-center initial"
                  v-bind:class="{ clickcard: active2 }"
                >
                  <b-avatar
                    size="70px"
                    :variant="activeAvatar2"
                    text="S"
                    v-bind:class="{ clickcard: active2 }"
                  >
                  </b-avatar>
                  <b-card-text class="mt-1">
                  <!-- <strong >Valid till {{  this.$store.state.userData.validity}}</strong> -->
                  </b-card-text>
                  <b-card-title
                    class="mt-2 initial"
                    v-bind:class="{ clickcard: active2 }"
                    >Silver package</b-card-title
                  >
                  <b-card-text>
                    The advertisement period is 6 month on Brand away
                    application Ad format (app main page) For AED 500 per
                    month.
                  </b-card-text>
                  <hr />
                  <b-card-text>
                    <strong>6 Months</strong> 
                  </b-card-text>
                </b-card>
              </a>
            </b-col>

            <b-col lg="3" md="4">
              <a>
                <b-card
                  @click="ClickAble3()"
                  tag="article"
                  style="max-width: 20rem;border-radius: 25px;"
                  class="text-center initial"
                  v-bind:class="{ clickcard: active3 }"
                >
                  <b-avatar
                    size="70px"
                    :variant="activeAvatar3"
                    text="G"
                    v-bind:class="{ clickcard: active3 }"
                  >
                  </b-avatar>
                  <b-card-title
                    class="mt-2 initial"
                    v-bind:class="{ clickcard: active3 }"
                    >Gold Package</b-card-title
                  >
                  <b-card-text>
                    The advertisement period is one year on Brand away
                    application Ad format (app main page) For AED 1000 per
                    month.
                  </b-card-text>
                  <hr />
                  <b-card-text>
                    <strong> 1 Year </strong> 
                  </b-card-text>
                </b-card>
              </a>
            </b-col>

          </b-row>
        
      </tab-content>
       
       <tab-content title="Make Payment" v-if="paymentScreen">
      <div class="misc-wrapper">

   <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 >
          Please make the payment to proceed with your account.
        </h2>
       <b-row  class="justify-content-md-center mt-3" >
        
         <b-col xl="6" lg="6" md="8" cols="12">
    <b-tabs pills nav-class="nav-pill-primary">
      <b-tab title="Yearly" @click="ChangePrice(amount,'yearly')" active/>
      <b-tab title="Monthly" @click="ChangePrice(amount,'monthly')" />

    
    </b-tabs>
           <h3 class="text-primary"> AED {{this.amount}}  </h3>
        <stripe-element-card
          class="mt-2"
          ref="elementRef"
          :pk="pubKey"
          @token="tokenCreated"
        />
           <b-button  :disabled="this.busy" block class="mt-2" @click="paymentBtn()"  variant="primary" >
          Pay Now
        </b-button>
         </b-col>
       
      </b-row>
     

        <!-- <b-img fluid :src="imgUrl" alt="Coming soon page" /> -->
      </div>
    </div>
  </div>
     
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  //BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  BAlert,
 // BTabs,
  //BTab,
  BButtonGroup,
  BSpinner,
  BCardImg,
  BCardTitle,
  BCardText,
  BButton,
 BTabs,
    BTab,
} from 'bootstrap-vue';
import { FormWizard, TabContent } from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import vSelect from 'vue-select';
import { ref, onUnmounted } from '@vue/composition-api';
import { avatarText } from '@core/utils/filter';
import flatPickr from 'vue-flatpickr-component';
import { BFormSelect } from 'bootstrap-vue';
import { StripeElementCard } from "@vue-stripe/vue-stripe";
//import Multiselect from "vue-multiselect";
export default {
  components: {
    //Multiselect,
  StripeElementCard,
    BCard,
    BRow,
    BCol,
    FormWizard,
    TabContent,
     BCardTitle,
    BCardText,
    //BFormInput,
    BButton,
     BTabs,
    BTab,
    //BTable,
    //BSpinner,
    //BCardImg,
    //BAlert,
    //BImg ,
    //BMedia,
    BAvatar,
    //BLink,
    //BBadge,
    //BButtonGroup,
   // BTabs,
    //BTab,
    //BDropdown,
    //BDropdownItem,
    //BPagination,
    //vSelect,
    //flatPickr,
  },
  props: {
    /* modalFilter: {
    type: [String, null],
    default: null,
    },
    modalFilterOptions: {
    type: Array,
    required: true,
    }*/
  },
  created() {
    this.LoadData();
  },
  computed: {
    filterOrders: function() {
      return this.items.filter(pro => {
        return pro.firstName.toLowerCase().match(this.searchQuery.toLowerCase());
      });
    },
  },
  data() {
    return {
      packageType:'Yearly',
      mydate:'',
      busy:false,
      sig:'',
      amount:'',
       pubKey:
        "pk_test_51K7vpSBWFhPZDTbf5VjL9kRYQn7VqgS08mj0WoEZZyLnDOc39k9HzLgjY1TokLLz5fiHHkXOrmn5C7y0xFI0LXtV005DMXJsGZ",
      paymentScreen:false,
      accepted:'',
      myindex:0,
      orderTabs:["Purchases","Fitting Room"],
      uaeTime: '',
      completed:'',
      ongoing:'',
      canceled:'',
      readyForDelivery: '',
      requiresActions: '',
      underProgress: '',
      date: '',
      searchQuery: '',
      loading: false,
      CakeDetails: [],
      tabIndex: 1,
      cover: '',
      fields: [
        {
          label: 'OrderId',
          key: 'id',
          isRowHeader: true,
        },
        { label: 'Customer', key: 'firstName' },
        'date',
        { label: 'Net Total', key: 'netTotal' },
        'status',
        'actions',
      ],
      items: [],
      selected: null,
      filterOptions: [
        { text: 'Most sold product', value: 'mostSold' },
        { text: 'Least sold product', value: 'leastSold' },
        { text: 'Most popular product', value: 'mostPopular' },
        { text: 'Least popular product', value: 'leastPopular' },
        { text: 'Most profitable product', value: 'mostProfitable' },
      ],
      FinishButton:'submit',
      rangeDate: null,
      myObj: {
        orderId: 0,
        userId: 0,
        bill: 0,
        vat: 0,
        discount: 0,
        modeOfpayment: '',
        vendorId: 0,
        deliveryCharges: 0,
        date: '',
        netBill: 0,
        totalBill: 0,
        transactionId: '',
        paidAmount: 0,
      },
      package:'',
      activeAvatar1: 'light-danger',
      activeAvatar2: 'light-danger',
      activeAvatar3: 'light-danger',

      active1: false,
      active2: false,
      active3: false,

      detailObj: {},
    };
  },
  methods: {
      ChangePrice(amount,pack)
    {
      this.packageType = pack;
      console.log(amount);
      if(amount == 3000 && pack == 'monthly' )
      {
        this.amount = 500;
      }
      if(amount == 500 && pack == 'yearly')
      {
        this.amount = 3000;
      }
      if(amount == 12000 && pack == 'monthly' )
      {
        this.amount = 1000;
      }if(amount == 1000 && pack == 'yearly' )
      {
        this.amount = 12000;
      }
    },
     paymentBtn()
    {
       var axios = require('axios');

var baseUrl = `https://dc.appick.io/stripeBrand`;
        axios.post(baseUrl, { amount: this.amount }).then((res) => {
          console.log(res);
          this.sig = res.data.clientSecret;

          this.$refs.elementRef.submit();
          this.busy = false;
          return true;
        });
    },
   tokenCreated(token) {
 var axios = require('axios');

      console.log("Token is created...");
      var url = "https://dc.appick.io/stripeBrand/charge";
    
      this.busy = true;
      axios
        .post(url, {
          stripeToken: token.id,
          amount: this.amount,
          about: this.$store.state.userData.business  ,
        })
        .then((res) => {
          console.log(res.data.charge);
          try {
            if (res.data.charge.captured) {
 var axios = require('axios');

        var data = {package:this.package,vendorId:this.$store.state.userData.userID,packageType:this.packageType};
        var config = {
          method: 'put',
          url: 'https://brandaway.fastech.pk/api/Login/Vendors/UpdatePackage',
          headers: {
            Authorization:
              'Bearer ' + this.$store.state.userData.token,
            'Content-Type': 'application/json',
          },
          data: data,
        };

        axios(config)
          .then(response => {
            //console.log(response.data.status);
            console.log(response.data);

            if (response.data.status == 'success') {
              console.log(response.data);

              Swal.fire(
                'Package updated!',
                'Your package is updated!',
                'success',
              ).then(res => {
                this.LoadData();
              });
            } else {
              console.log('notworking');
            }
          })
          .catch(error => {
            console.log(error);
          });
        }
             
            
          } catch (error) {
            this.busy = false;
            return this.$swal.fire({
              icon: "error",
              title: "Error",
              text: "Unable to process payment, check card info again.",
            });
          }
        });
    },

     validationPackage() {
      return new Promise((resolve, reject) => {
         //  resolve(true);

        if (this.package !== '') {
          resolve(true);
        } else {
          resolve(false);
        }
      });
     },
      formSubmitted() {
       
    },
      ClickAble1() {
        console.log("--------------",this.$store.state.userData.productsCount);
        
        if(this.$store.state.userData.productsCount > 5)
        {
          Swal.fire(
                'Not Allowed!',
                'Your cannot downgrade your package to bronze your products limit is greater then 5.',
                'warning',
              );
        }
        else
        {

      this.active2 = false;
      this.active3 = false;
      //this.package = 'Bronze';
      //this.activeAvatar2 == 'light';
      //this.activeAvatar3 == 'light';
      this.active1 = !this.active1;
      if (this.active1) {
        this.package = 'Bronze';
        this.paymentScreen = false
        this.FinishButton = 'Submit';

      } else {
        this.package = '';
      }
      console.log(this.package);

      if (this.activeAvatar1 == 'light') {
        this.activeAvatar1 = 'light-danger';
      } else {
        this.activeAvatar1 = 'light';
      }
      this.activeAvatar3 = 'light-danger';
      this.activeAvatar2 = 'light-danger';

      // this.activeAvatar1 == 'light';
      // if(this.active2 == false && this.active3 == false)
      // {
      // this.active1 = !this.active1;

      // }
        }
        
    },
    ClickAble2() {
      this.active1 = false;
      this.active3 = false;

      //this.activeAvatar2 == 'light';
      //this.activeAvatar3 == 'light';
      this.active2 = !this.active2;
      if (this.active2) {
        this.package = 'Silver';
        this.paymentScreen = true;
        this.amount = 3000;
        this.FinishButton = null;

      } else {
        this.package = '';
      }
      console.log(this.package);
      //    this.activeAvatar2 == 'light';
      if (this.activeAvatar2 == 'light') {
        this.activeAvatar2 = 'light-danger';
      } else {
        this.activeAvatar2 = 'light';
      }
      this.activeAvatar1 = 'light-danger';
      this.activeAvatar3 = 'light-danger';
      //    if(this.active3 == false && this.active1 == false)
      //   {
      //   this.active2 = !this.active2;
      //  if(this.activeAvatar2 == 'light')
      //   {
      //   this.activeAvatar2 = 'light-danger';
      //   }
      //   else
      //   {
      //   this.activeAvatar2 = 'light';

      //   }
      //}
    },
    ClickAble3() {
      this.active1 = false;
      this.active2 = false;
      // this.package = 'Gold';
      //this.activeAvatar2 == 'light';
      //this.activeAvatar3 == 'light';
      this.active3 = !this.active3;
      //this.activeAvatar3 == 'light';
      if (this.active3) {
        this.package = 'Gold';
        this.paymentScreen = true;
        this.amount = 12000;
        this.FinishButton = null;
      } else {
        this.package = '';
      }
      console.log(this.package);
      if (this.activeAvatar3 == 'light') {
        this.activeAvatar3 = 'light-danger';
      } else {
        this.activeAvatar3 = 'light';
      }
      this.activeAvatar1 = 'light-danger';
      this.activeAvatar2 = 'light-danger';
     
    },
       Details(index) {
      this.myindex = index;
      console.log(index);
     //s this.detailObj = this.items[index];
    },
    GetOrders(status)
    {
        var axios = require('axios');

      var config = {
        method: 'get',
        url:'https://brandaway.fastech.pk/api/Bookings/GetVendorBookingsStatus/' + this.$store.state.userData.userID + '/' + status,
        headers: {
          Authorization:
            'bearer ' +  this.$store.state.userData.token ,
        },
      };

      axios(config)
        .then(response => {
        this.completed =response.data.completed;
    this.ongoing = response.data.ongoing;
    this.canceled =  response.data.canceled;
    this.accepted =  response.data.accepted;

    // this.items =  response.data.data; 
    this.items =[];
    response.data.data.forEach(elem => {
      if(elem.status == "On going")
      {
        elem.status = {status:"On going",variant:"warning"};
        var d = new Date(elem.date);
        elem.date = d.toDateString();
        this.items.push(elem);
      }
     if(elem.status == "Canceled")
      {
        elem.status = {status:"Canceled",variant:"danger"};
        var d = new Date(elem.date);
        elem.date = d.toDateString();
        this.items.push(elem);
      }
        if(elem.status == "Accepted")
      {
        elem.status = {status:"Accepted",variant:"success"};
        var d = new Date(elem.date);
        elem.date = d.toDateString();
        this.items.push(elem);
      }
      if(elem.status == "Completed")
      {
        elem.status = {status:"Completed",variant:"success"};
        var d = new Date(elem.date);
        elem.date = d.toDateString();
        this.items.push(elem);

      }
    });
console.log("Hello  UItems", this.items);
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    rowClass(item, type) {
      const colorClass = 'table-danger';
      if (!item || type !== 'row') {
        return;
      }

      // eslint-disable-next-line consistent-return
      if (item.date < this.uaeTime && item.status.status == 'Under progress') {
        return colorClass;
      }
      if (
        item.date < this.uaeTime &&
        item.status.status == 'Requires actions'
      ) {
        return colorClass;
      }
    },
    filterData() {
      console.log('Hello');
      let from = this.date.split(' ')[0];
      let to = this.date.split(' ')[2];
      console.log(from, to);
      if (from == undefined || to == undefined) {
        return this.LoadData();
      }
      //this.loading = true;
      // data
      // this.$http.get('/ecommerce/data')
      //   .then(response => {
      //     this.data = response.data

      //     // ? Your API will return name of logged in user or you might just directly get name of logged in user
      //     // ? This is just for demo purpose
      //     const userData = getUserData()
      //     this.data.congratulations.name = userData.fullName.split(' ')[0] || userData.username
      //   })
      var axios = require('axios');

      var config = {
        method: 'get',
        url:
          'https://okaaik.fastech.pk/api/oOrders/getoOrdersbyfilter/' +
          this.$store.state.userData.userID +
          '/?fromdate=' +
          from +
          '&todate=' +
          to,
        headers: {
          Authorization:
            'bearer 56EeZXfkyt2E4yYawlxCo3FFMZiFmhDs2VZbA9jLIfTs70L_z5gC9yAMCchwnNRUcicotXJNc3GcSB8M-T9i8PLd-HUMk4KXom3Uu_kC4kbFhSk02Z9YQRwZqVdXjECBVCA0gOhnVoRLztCFQxNgVJbeWEMubqzh8dFpvnkIoC_2FF0kyCzKbwj4SYtF1fMMyhy2CO8DOIWUTZtdpwpI-IjGB0CyHhav_I_ag72apOUtKKhAoshLdYfcFz3QBYC0Pj-FIyEbzz_S5Rcp9fiIG3y5UM6RtSf8zP08M42rVjkLHwOb5cc8YafdfFExhGFI',
        },
      };

      axios(config)
        .then(response => {
          // console.log(JSON.stringify(response.data));

          //  this.data  =  response.data;
          //console.log( response.data);
          if (response.data.status === 'success') {
            console.log('mydata', response.data);
            this.items = [];
            response.data.query.forEach(elem => {
              elem.date = elem.date.split('T')[0];
              this.items.push(elem);
            });

            //this.data = response.data;
          }

          //   this.myProducts = response.data.data;
          //    console.log(this.myProducts);
          // if(this.myProducts.data.length == 0)
          //  {
          //    this.productEmpty = true;
          //  }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    Billing(item) {
      console.log(item);
      this.loading = true;
      var axios = require('axios');

      var config = {
        method: 'get',
        url:
          'https://okaaik.fastech.pk/api/oOrders/Detailsbyorders/' +
          item.orderId,
        headers: {
          Authorization:
            'bearer 56EeZXfkyt2E4yYawlxCo3FFMZiFmhDs2VZbA9jLIfTs70L_z5gC9yAMCchwnNRUcicotXJNc3GcSB8M-T9i8PLd-HUMk4KXom3Uu_kC4kbFhSk02Z9YQRwZqVdXjECBVCA0gOhnVoRLztCFQxNgVJbeWEMubqzh8dFpvnkIoC_2FF0kyCzKbwj4SYtF1fMMyhy2CO8DOIWUTZtdpwpI-IjGB0CyHhav_I_ag72apOUtKKhAoshLdYfcFz3QBYC0Pj-FIyEbzz_S5Rcp9fiIG3y5UM6RtSf8zP08M42rVjkLHwOb5cc8YafdfFExhGFI',
        },
      };

      axios(config)
        .then(response => {
          // console.log(JSON.stringify(response.data));

          //  this.data  =  response.data;
          //console.log(response.data.orderDetails);
          this.CakeDetails = [];
          //this.product = response.data.orderDetails;
          this.CakeDetails = response.data.orderDetails;
          console.log(this.CakeDetails);
          this.myObj.orderId = item.orderId;
          this.myObj.userId = item.userId;
          this.myObj.vendorId = item.vendorId;
          this.myObj.modeOfpayment = item.modeOfpayment;
          this.myObj.bill = item.bill;
          this.myObj.vat = item.vat;
          this.myObj.deliveryCharges = item.deliveryCharges;
          this.myObj.totalBill = item.totalBill;
          this.myObj.discount = item.discount;
          this.myObj.netBill = item.netBill;
          this.myObj.transactionId = item.transactionId;
          this.myObj.paidAmount = item.paidAmount;
          this.loading = false;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    openDetails(id) {
      this.loading = true;
      console.log(id);
      var axios = require('axios');

      var config = {
        method: 'get',
        url: 'https://okaaik.fastech.pk/api/oOrders/Detailsbyorders/' + id,
        headers: {
          Authorization:
            'bearer 56EeZXfkyt2E4yYawlxCo3FFMZiFmhDs2VZbA9jLIfTs70L_z5gC9yAMCchwnNRUcicotXJNc3GcSB8M-T9i8PLd-HUMk4KXom3Uu_kC4kbFhSk02Z9YQRwZqVdXjECBVCA0gOhnVoRLztCFQxNgVJbeWEMubqzh8dFpvnkIoC_2FF0kyCzKbwj4SYtF1fMMyhy2CO8DOIWUTZtdpwpI-IjGB0CyHhav_I_ag72apOUtKKhAoshLdYfcFz3QBYC0Pj-FIyEbzz_S5Rcp9fiIG3y5UM6RtSf8zP08M42rVjkLHwOb5cc8YafdfFExhGFI',
        },
      };

      axios(config)
        .then(response => {
          // console.log(JSON.stringify(response.data));

          //  this.data  =  response.data;
          console.log(response.data.orderDetails);
          this.CakeDetails = [];
          //this.product = response.data.orderDetails;
          response.data.orderDetails.forEach(elem => {
            elem.date = elem.date.split('T')[0];
            this.CakeDetails.push(elem);
          });
          console.log(this.CakeDetails);

          this.loading = false;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    OrderStatus(id, status) {
      Swal.fire({
        title: 'Do you want to ' + status + ' this booking?',
        text: "You won't be able to revert this!",
        icon: 'question',
        iconColor: '#FB595f',
        confirmButtonText: 'Yes',
        confirmButtonColor: '#FB595f',
      }).then(result => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          var axios = require('axios');
          var config = {
            method: 'put',
            url:
              'https://brandaway.fastech.pk/api/Bookings/UpdateBookingsStatus/' + id  +'/' + status
             ,
            headers: {
              Authorization:
                'bearer ' + this.$store.state.userData.token,
            },
          };
          console.log(config.url);
          axios(config)
            .then(response => {
              console.log(JSON.stringify(response.data));
              if (response.data.status === 'success') {
                Swal.fire(status + '!', '', 'success').then(res => {
                  this.LoadData();
                });
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        }
      });
    },
    DenyOrder(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, reject it!',
      }).then(result => {
        if (result.isConfirmed) {
          var axios = require('axios');
          var config = {
            method: 'put',
            url:
              'https://okaaik.fastech.pk/api/oOrders/UpdateOrderStatus/?id=' +
              id +
              '&check=false',
            headers: {
              Authorization:
                'bearer 56EeZXfkyt2E4yYawlxCo3FFMZiFmhDs2VZbA9jLIfTs70L_z5gC9yAMCchwnNRUcicotXJNc3GcSB8M-T9i8PLd-HUMk4KXom3Uu_kC4kbFhSk02Z9YQRwZqVdXjECBVCA0gOhnVoRLztCFQxNgVJbeWEMubqzh8dFpvnkIoC_2FF0kyCzKbwj4SYtF1fMMyhy2CO8DOIWUTZtdpwpI-IjGB0CyHhav_I_ag72apOUtKKhAoshLdYfcFz3QBYC0Pj-FIyEbzz_S5Rcp9fiIG3y5UM6RtSf8zP08M42rVjkLHwOb5cc8YafdfFExhGFI',
            },
          };
          console.log(config.url);
          axios(config)
            .then(response => {
              console.log(JSON.stringify(response.data));
              if (response.data.status === 'success') {
                Swal.fire(
                  'Rejected!',
                  'the order has been rejected.',
                  'success',
                ).then(res => {
                  this.LoadData();
                });
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        }
      });
    },
        LoadData() {
        //    var d = new Date(this.$store.state.userData.validity);
        // this.mydate = d.toDateString();
        //   if(this.$store.state.userData.service == 'Bronze')
        //   {
        //     this.ClickAble1();
        //   }
        //   if(this.$store.state.userData.service == 'Silver')
        //   {
        //     this.ClickAble2();

        //   }
        //   if(this.$store.state.userData.service == 'Gold')
        //   {
        //     this.ClickAble3();

        //   }
          
    }
  },

  // setup() {
  //   const ORDER_APP_STORE_MODULE_NAME = 'app-order'

  //   // Register module
  //   // if (!store.hasModule(ORDER_APP_STORE_MODULE_NAME)) store.registerModule(ORDER_APP_STORE_MODULE_NAME, orderStoreModule)

  //   // // UnRegister on leave
  //   // onUnmounted(() => {
  //   //   if (store.hasModule(ORDER_APP_STORE_MODULE_NAME)) store.unregisterModule(ORDER_APP_STORE_MODULE_NAME)
  //   // })

  //   /*const modalFilterOptions = [
  //     { label: 'Most sold product', value: 'mostSold' },
  //     { label: 'Least sold product', value: 'leastSold' },
  //     { label: 'Most popular product', value: 'mostPopular' },
  //     { label: 'Least popular product', value: 'leastPopular' },
  //     { label: 'Most profitable product', value: 'mostProfitable' },
  //   ]*/

  //   const {

  //     tableColumns,
  //     perPage,
  //     currentPage,
  //     totalOrders,
  //     dataMeta,
  //     perPageOptions,
  //     searchQuery,
  //     sortBy,
  //     isSortDirDesc,
  //     refOrderListTable,
  //     refetchData,

  //    // modalFilter,
  //   } = useOrdersList()

  //   return {

  //     // Sidebar
  //     //isAddNewOrderSidebarActive,

  //     fetchMyOrders,
  //     tableColumns,
  //     perPage,
  //     currentPage,
  //     totalOrders,
  //     dataMeta,
  //     perPageOptions,
  //     searchQuery,
  //     sortBy,
  //     isSortDirDesc,
  //     refOrderListTable,
  //     refetchData,
  //     // Filter
  //     avatarText,
  //     //avatarText,
  //     //modalFilterOptions,
  //     //modalFilter,

  //   }
  // },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
<style>
.initial {
  color: #f88379;
}
.clickcard {
  background-color: #f88379;
  color: white;
}
.card {
  transition: 0.3s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
